import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ReactMarkdown from 'react-markdown';


/*function Introduction() {
    return (
        <Box>
            <Typography variant="h5" component="div" gutterBottom>
                XCampus 简介
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;XCampus APP的主要功能有:课程表、计划、邮箱等，并提供非常完善的自定义功能，用户可以调整设置来达到自己的需求。
            </Typography>
            <Typography variant="h5" component="div" gutterBottom>
                功能介绍
            </Typography>
            <Typography variant="h6" component="div" gutterBottom>
                1.课程表功能
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;课程表基本功能包括添加、删除、修改、查看课表，设置开学日期后，XCampus会自动计算当前所在的周，可在周视图和日视图中自由切换，并可切换至其他周的课表。
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持多课表，课表数量不限，可自由切换、添加、删除、重命名。
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;包括众多自定义功能，诸如自定义背景（其中背景可以设置为本地图片，也可以设置为网络图片、纯色背景或无背景）、自定义课程可见度（配合背景使用时，可见度低则课程所遮住的背景图片更清晰可见，可见度高则课程所遮住的背景图片更加不可见）、自定义显示时间（从周一到周日均为可选择显示的时间，用户可以根据自己的需求隐藏指定的时间，这样可以让课表界面更加整洁！）、自定义上课时间表（登录后设置学校，若有同校学生配置过时间表且同意共享，则会自动设置为该学校的时间表）、自定义教务系统（登录后设置学校，若学校教务系统信息在XCampus数据库中，则会自动配置，否则可自己设置教务系统类型和教务系统的网址）、震动反馈开关、自定义时间表颜色（即课程表顶端显示的周x，以及左侧显示的课程节数）自定义时间段颜色（课程表左侧显示的xx:xx-xx:xx的颜色）、自定义课程边框（可开启和关闭、开启则可配置边框粗细，获得自己最舒服的体验！）
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;登录后，即可享受云端功能，包括课表分享（分享码）、同步课表到账号以及从账号恢复课表，多端同步更加方便！
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iOS端已支持课程表小组件，且在XCampus设置中可以自定义小组件的外观，让您的小组件更加与您桌面的风格相符！
            </Typography>
            <br></br>
            <Typography variant="h6" component="div" gutterBottom>
                2.计划功能
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;计划功能包含两个子功能：TODO和长期计划。
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TODO功能包括添加、删除、排序、查看，分为两个区域：今日 TODO和所有 TODO，在今日TODO中显示今天的待办事项和备注，所有TODO则显示所有的待办事项和相应的时间。在修改页面长按待办项可排序。
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;长期计划包括添加、删除、排序、修改、查看，没有时间限制，若完成则可勾选事项或直接删除。
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iOS端已支持计划小组件，可在XCampus设置中定义小组件的外观，让您的小组件更加与您桌面的风格相符！
            </Typography>
            <br></br>
            <Typography variant="h6" component="div" gutterBottom>
                3.邮箱功能
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;用户设置完邮箱后，可以将XCampus的邮箱功能当作一个简易的便携的邮件客户端，目前共支持 收件箱、已发送、草稿箱、已删除、垃圾箱 五个邮箱文件夹。
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;XCampus邮箱客户端支持发送邮件，且可添加多个收件人。
            </Typography>
            <br></br>
            <Typography variant="h6" component="div" gutterBottom>
                4.外观自定义功能
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;除课程表外观支持自定义外，XCampus APP整体的外观也支持多种自定义。
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.主题颜色：主题颜色包括底部导航栏、绝大多数按钮的颜色、以及弹窗的颜色。
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.图标颜色：包括底部导航栏的图标颜色。
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.深色模式开关：打开后软件无缝过渡为深色模式。
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.底部导航栏显示开关：开启则显示底部导航栏，关闭则会隐藏导航栏，默认显示功能开关开启的第一个页面。关闭后，用户可通过长按桌面上应用图标，利用QUICK_ACTION切换已打开的功能，用户按下顶部应用栏中的<RemoveRedEyeOutlinedIcon />按钮即可恢复底部导航栏的显示。
            </Typography>
            <Typography variant="body1" gutterBottom>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.功能开关：XCampus APP所包含的课程表、计划、邮箱三个主要功能均可以开启和关闭，关闭后，底部导航栏将不会显示该功能的图标，QUICK_ACTION中相应的选项也会消失。
            </Typography>
        </Box>
    )
}

*/

function Introduction() {

    const content = `

​	XCampus是一款开发于2023年，主要面向大学生群体，以课程表为主要功能，日程计划、邮箱等功能为辅，具有高度自定义性的跨端APP。

## 功能列表

- 课程表

- 计划

- 邮箱

## 功能介绍

### 1.课程表

课程表是XCampus的主要功能，目前已经实现添加、修改、删除、查看课程的功能。

#### 课表界面

- 实时设置您可在您自己的课表直接设置您的外观，也可以使用XCampus内置的示例周。
- 可自定义显示的日期（如若您周六周日无课，则可将周六周日设置为不可见状态，工作日同理）。
- 自定义每节课的上下课时间（若您已登录XCampus，则可以加入时间表共享或者查看同校共享的时间表，避免自己配置的繁琐）。
- 在课程表设置中设置上课地点后，可以给课程中的上课地点设置更详细的地点。
- 震动反馈 —— 在您切换周数时，给您合适的震感提升舒适度。
- 自定义背景，包括以下
  - 本地图片 - 支持裁剪后设置为课程表的背景图片
  - 网络图片 - 提供图片链接作为背景
  - 纯色背景 - 使用纯颜色作为背景
- 自定义课程透明度
- 自定义课程圆角
- 自定义文字颜色
- 自定义课程详情展示形式 - 弹窗或从下弹出
- 课程边框 - 可调节边框线的粗细

#### 添加、修改课程

- 能给课程设置多个时段、多个老师，让您的课表看上去更整齐、简洁，您可以更方便、集中地管理课程。

- 支持添加自定义信息，让您每次打开课程都能直接看到为您的课程添加的更多备注。

- 支持课程自定义颜色，让您的课表看上去更加美观。

#### 调课

- 可将一整天的课调整到另一天
- 可将单节课暂时添加到某天的某时间段


#### 教务系统导入

- 已支持的教务系统列表

  - 正方教务

  - 金智教务

  - 南航教务

  - 翱翔教务

  - 清华教务
- 登录后设置学校，若学校教务系统信息存在于XCampus数据库中，将会自动配置教务系统设置。
- 在支持查看课程详细信息的教务系统中，支持导入课程的详细信息（完善课程按钮）。
- 教务系统功能改善计划：检测成功导入的学校并完善学校信息

#### 多课表

- 您可在至少保留一个课表的情况下，添加、删除、重命名、切换课表
- 课表数量无限制


#### 课表共享

- 您可自定义或随机生成对应于您的课表的分享码，并将其告知您的伙伴。

- 在导入课表中使用分享码导入功能，输入分享码可导入共享的课表

#### 账号同步

- 登录后，您可使用此功能将当前选中的课程表同步到账号中，在其他任何运行XCampus的设备中都可以导入。

- 支持最多5个同步的课表同时存在于账户

#### 导出到日历

- 在您给XCampus授予权限后，您可以将当前使用的课表导出到日历
- 需要正确设置时间表
- 可自定义日历名称和事件颜色
- 可设置多个上课前提醒

### 2. 计划

计划分为课程查看、TODO和长期计划两个板块

#### 课程查看

- 当课程表设置中 计划中显示课程 开关为开状态时，在待办页面顶部显示正在进行中的课程和下一节课。
- 短按在进行中的课程和下一节课切换
- 长按显示该课程的详细信息

#### TODO

##### 添加TODO

- 给TODO设置详细信息
- 提供四种重复方式
  - 今天 - 设置仅限于今天的 TODO
  - 日期 - 设置从开始时间到结束时间每天的TODO（24:00重置）
  - 每周 - 选择周一～周日的重复（例如您选择周一、三、五，则每周一、三、五会呈现相应TODO）
  - 每天 - 设置每天的TODO

##### 右滑删除TODO

- 在 今日TODO 和 所有TODO 栏中右滑TODO项目，可以删除TODO

##### TODO 删除、排序

- 点击 所有TODO 中的编辑按钮，进入TODO编辑页面，在此页面，您可以长按拖动给TODO排序，也可以删除TODO。

#### 长期计划

- 计划不会随着时间的变化而重置
- 长按拖动排序
- 右滑删除长期计划
- 给计划自定义颜色

### 3. 邮箱

#### 邮箱设置、接受邮件

- 使用邮箱前，在邮箱设置中设置邮箱服务器、账号和密码
- 邮箱功能内置多个收件箱，可切换收件箱
- 邮件缓存，提升操作体验

#### 发送邮件

- 可指定多个收件人
- 后续版本将支持附件发送

### More : 设置

- 设置学校

### More : 小组件(iOS)

#### 课程表小组件

- 小尺寸、中等尺寸两个尺寸大小的小组件供您选择
- 可在 设置 - 小组件设置 中设置小组件的背景颜色（白色、深色、根据课程颜色、自定义颜色）

#### 计划小组件

- 可直观地看到您今天未完成的 TODO
- 可在 设置 - 小组件设置 中设置小组件的背景颜色（白色、深色、自定义颜色）

### 应用内上课通知(iOS)

- 可在 课程表设置 中开启和关闭应用内课程通知

- 可自定义提前提醒时间





    `

    return (
        <Box>
            <Typography variant="h5" component="div" gutterBottom>
            XCampus 简介
            </Typography>
            <ReactMarkdown children={content} />
            
        </Box>
    )
}

export default Introduction