import React from 'react';
import { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Index from './pages/index';
import Introduction from './pages/introduction';
import Service from './pages/service';
import Contact from './pages/contact';
import PrivacyService from './pages/privacy';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Drawer, CssBaseline } from '@mui/material';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ArticleIcon from '@mui/icons-material/Article';
import PhoneIcon from '@mui/icons-material/Phone';

let pages = [<Index />, <Introduction />];
function App() {
  const drawerWidth = 240;

  const [page, setPage] = React.useState(<Index />);
  const [isOpen, setIsOpen] = React.useState(false);
  var query = window.location.search.substring(1);

  useEffect(() => {
    if (query.indexOf("page=") === -1 || query.indexOf("page=index") !== -1) {
      setPage(<Index />);
    }
    if (query.indexOf("page=introduction") !== -1) {
      setPage(<Introduction />);
    }
    if (query.indexOf("page=service") !== -1) {
      setPage(<Service />);
    }
    if (query.indexOf("page=privacy") !== -1) {
      setPage(<PrivacyService />);
    }
    if (query.indexOf("page=contact") !== -1) {
      setPage(<Contact />);
    }
  }, []);


  const drawerList = (
    <Box
      sx={{ overflow: 'auto' }}
    //sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
    //role="presentation"
    //onClick={toggleDrawer(anchor, false)}
    //onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => {
            setPage(<Index />);
          }}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={"首页"} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={() => {
            setPage(<Introduction />);
          }}>
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary={"应用介绍"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => {
            setPage(<Service />);
          }}>
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary={"用户协议"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => {
            setPage(<PrivacyService />);
          }}>
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary={"隐私政策"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => {
            setPage(<Contact />);
          }}>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText primary={"联系我们"} />
          </ListItemButton>
        </ListItem>
      </List>

    </Box>

  )

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setIsOpen(!isOpen)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              XCampus APP
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={isOpen}
            onClose={() => setIsOpen(false)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            <Toolbar />
            {drawerList}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            <Toolbar />
            {drawerList}
          </Drawer>
        </Box>
        <Box
          component="main"
          className='pageBody'
          sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
        >
          <Toolbar />
          {page}
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh', bgcolor: 'background.paper' }}>
            <Typography variant="body1" gutterBottom sx={{ color: "#000" }}>
              Copyight © 2023 南京炡云数字科技有限公司 版权所有
            </Typography>
          </Box>
          <a href="http://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
            <center>
              <Typography variant="body1" gutterBottom sx={{ color: "#000" }}>
                苏ICP备2023031417号-1
              </Typography>
            </center>
          </a>
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32010002010007" rel="noreferrer" target="_blank">
            <center>
              <Typography variant="body1" gutterBottom sx={{ color: "#000" }}>
                <img src="http://www.beian.gov.cn/img/ghs.png" alt="国家公安机关备案图标" />
                苏公网安备32010002010007号
              </Typography>
            </center>
          </a>
        </Box>

      </Box>
    </>
  );
}

export default App;
