import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ReactMarkdown from 'react-markdown';

import { Card, CardActionArea, CardMedia, CardContent, CardActions, Button } from '@mui/material';

function PrivacyService() {

    const content = `
*最后更新日期：2023.10.5*
    
1. **引言**
    
    感谢您使用XCampus APP。本隐私政策旨在帮助您了解我们如何收集、使用和保护您的个人信息。请在使用本应用程序之前仔细阅读此政策。
    
2. **信息收集和使用**
    
    我们可能会收集和使用以下类型的信息：
    
    - **个人信息：** 我们可能会收集您的联系信息等个人信息，以便提供特定服务或与您进行沟通。
    
3. **信息保护**
    
    我们采取合理的措施来保护您的个人信息，防止未经授权的访问、使用、修改或泄露。
    
4. **信息共享**
    
    我们不会出售、出租或分享您的个人信息给第三方，除非获得您的明确同意或法律要求。
    
5. **隐私权更新**
    
    我们可能会定期更新本隐私协议，以反映变化。我们将通过应用程序通知您有关协议更新的信息。
    
6. **联系我们**
    
    如果您对本隐私协议或您的个人信息有任何问题或疑虑，请通过以下联系方式与我们联系：
    
    [Email](mailto:cxbsoft@bsot.cn)
    
    QQ : 3319066174
    
    

    `

    return (
        <Box>
            <Typography variant="h5" component="div" gutterBottom>
                隐私政策
            </Typography>
            <ReactMarkdown children={content} />
            
        </Box>
    )
}

export default PrivacyService