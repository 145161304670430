import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ReactMarkdown from 'react-markdown';

import { Card, CardActionArea, CardMedia, CardContent, CardActions, Button } from '@mui/material';

function Service() {

    const content = `

请在使用本App之前仔细阅读以下条款和条件。通过访问或使用本App，表示您同意遵守以下条款和条件。如果您不同意这些条款，请不要使用本App。

**1. 使用限制**

1.1 您同意不会以任何违法、欺诈、虚假、侵权或滋扰的方式使用本App。

1.2 您同意不会干扰或破坏与本App相关的任何网络、服务器或基础设施。

1.3 您同意不会尝试未经授权访问本App的部分或全部内容，或者未经授权访问其他用户的账户信息。

**2. 隐私政策**

2.1 我们尊重用户的隐私权。有关我们如何收集、使用和保护您的个人信息的详细信息，请参阅我们的隐私政策。

**3. 知识产权**

3.1 本App及其内容（包括但不限于文字、图像、标识、图标、音频剪辑、视频剪辑等）受版权和其他知识产权法保护。

3.2 您不得未经许可复制、分发、展示、修改、创建衍生作品、出售或以任何其他方式利用本App的内容。

**4. 免责声明**

4.1 本App及其内容按"现状"提供，不提供任何明示或暗示的保证。

4.2 我们不对任何直接或间接的损害、利润损失、数据损失等承担责任。

**5. 变更和终止**

5.1 我们保留随时修改、暂停或终止本App的权利，恕不另行通知。

5.2 您可以随时停止使用本App。

**6. 适用法律**

6.1 这些条款受所在地法律的管辖。

6.2 如果这些条款中的任何部分被认定为无效或不可执行，不影响其他部分的有效性。

    `

    return (
        <Box>
            <Typography variant="h5" component="div" gutterBottom>
                用户协议
            </Typography>
            <ReactMarkdown children={content} />
            
        </Box>
    )
}

export default Service