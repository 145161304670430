import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Card, CardActionArea, CardMedia, CardContent, CardActions, Button } from '@mui/material';

function Index() {
    const cxbCard = (
        <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height={window.innerWidth > 600 ? "300" : "100"}
                    image="/static/cxb.jpg"
                    alt="cxbsoft"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        蔡徐博
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        擅长C++,Python,Golang,Dart,PHP等开发。
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" href='https://github.com/cxb-soft'>GITHUB</Button>
                </CardActions>
            </CardActionArea>
        </Card>
    )
    return (
        <Box>
            <Typography variant="h5" component="div" gutterBottom>
                XCampus 概念
            </Typography>
            <Typography variant="body1" gutterBottom>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;XCampus APP是由南京炡云数字科技有限公司开发的一款面向大学生和办公人群的APP，旨在为大学生和办公人群提供一个更便捷的学习和日程安排的平台。
            </Typography>
            <Typography variant="h5" component="div" gutterBottom>
                开发历程
            </Typography>
            <Typography variant="body1" gutterBottom>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2023年6月,XCampus APP开始开发,采用跨端框架Flutter + 原生开发的方式并完成课程表的一些基本功能。
            </Typography>
            <Typography variant="body1" gutterBottom>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2023年7月,后端主要使用Golang开始开发,服务器位于中国香港,并在XCampus APP中加入了一些新的功能,诸如“计划”、“邮箱”和多种自定义设置。
            </Typography>
            <Typography variant="body1" gutterBottom>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2023年8月,XCampus APP功能基本完成,南京炡云数字科技有限公司成立。
            </Typography>
            <Typography variant="body1" gutterBo ttom>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2023年9月,XCampus APP 1.0.0版本拟上架。
            </Typography>
            <Typography variant="h5" component="div" gutterBottom>
                开发者
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', p: 1, m: 1, bgcolor: 'background.paper' }}>
                <Box sx={{ p: 1, m: 1, }}>{cxbCard}</Box>
            </Box>
        </Box>
    )
}

export default Index

/**
 * 
 * 'use client';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Card, CardActionArea, CardMedia, CardContent, CardActions, Button } from '@mui/material';



const Index = () => {
  const cxbCard = (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image="https://img1.imgtp.com/2023/09/06/4QXVhRcH.png"
          alt="cxbsoft"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            蔡徐博
          </Typography>
          <Typography variant="body2" color="text.secondary">
            擅长C++,Python,Golang,Dart,PHP等开发。
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" href='https://github.com/cxb-soft'>GITHUB</Button>
        </CardActions>
      </CardActionArea>
    </Card>
  )
  const tyxCard = (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image="https://img1.imgtp.com/2023/09/06/sXEDBToX.png"
          alt="tyx"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            唐语昕
          </Typography>
          <Typography variant="body2" color="text.secondary">
            股东 南京航空航天大学2023级
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
  // 大屏设备并排显示，小屏设备上下显示
  return (
    <Box>
      <Typography variant="h5" component="div" gutterBottom>
        XCampus 简介
      </Typography>
      <Typography variant="body1" gutterBottom>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;XCampus APP是由南京炡云数字科技有限公司开发的一款面向大学生和办公人群的APP，旨在为大学生和办公人群提供一个更便捷的学习和日程安排的平台。
      </Typography>
      <Typography variant="h5" component="div" gutterBottom>
        开发历程
      </Typography>
      <Typography variant="body1" gutterBottom>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2023年6月,XCampus APP开始开发,采用跨端框架Flutter + 原生开发的方式并完成课程表的一些基本功能。
      </Typography>
      <Typography variant="body1" gutterBottom>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2023年7月,后端主要使用Golang开始开发,并在XCampus APP中加入了一些新的功能,诸如“计划”、“邮箱”和多种自定义设置。
      </Typography>
      <Typography variant="body1" gutterBottom>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2023年8月,XCampus APP功能基本完成,南京炡云数字科技有限公司成立。
      </Typography>
      <Typography variant="body1" gutterBottom>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2023年9月,XCampus APP 1.0.0版本拟上架。
      </Typography>
      <Typography variant="h5" component="div" gutterBottom>
        开发者
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', p: 1, m: 1, bgcolor: 'background.paper' }}>
        <Box sx={{ p: 1, m: 1, }}>{cxbCard}</Box>
      </Box>

    </Box>
  )
}

export default Index;
 */