import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Card, CardActionArea, CardMedia, CardContent, CardActions, Button } from '@mui/material';

function Contact() {
    const cxbCard = (
        <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height={window.innerWidth > 600 ? "300" : "100"}
                    image="/static/cxb.jpg"
                    alt="cxbsoft"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        蔡徐博
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        法人、股东、执行董事、联系人
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" href='https://github.com/cxb-soft'>GITHUB</Button>
                    <Button size="small" href='mailto:cxbsoft@bsot.cn'>Email</Button>
                </CardActions>
            </CardActionArea>
        </Card>
    )
    return (
        <Box>
            <Typography variant="h5" component="div" gutterBottom>
                联系我们
            </Typography>
            <Typography variant="body1" gutterBottom>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如果您有任何问题或建议，请通过以下方式联系我们：
            </Typography>
            {cxbCard}
        </Box>
    )
}

export default Contact